import "./Header.css";
import React from "react";
import logo from "./Screenshot 2024-07-17 at 1.50.16 PM.png";

const Header = ({ siteName }) => {
  return (
    <div className="header">
      <div className="SiteNameContainer">
        <img className="logo" src={logo} alt="logo" />
        {/* <p className="SiteName">{siteName}</p> */}
      </div>
      <nav className="navContainer">
        <ul className="navmenu">
          <li>
            <a href="#">Projects</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
