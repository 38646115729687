import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      © 2024 Waterloon Technologies - All Rights Reserved
    </div>
  );
};

export default Footer;
